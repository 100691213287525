export const Notification = {
  driver: {
    create: {
      success: 'driver_create_success',
      error: 'driver_create_error',
    },
    update: {
      success: 'driver_update_success',
      error: 'driver_update_error',
    },
    delete: {
      success: 'driver_delete_success',
      error: 'driver_delete_error',
    },
    execute: {
      success: 'driver_execute_success',
      error: 'driver_execute_error',
    },
    approve: {
      success: 'driver_approve_success',
      error: 'driver_approve_error',
    },
    reject: {
      success: 'driver_reject_success',
      error: 'driver_reject_error',
    },
  },
  allocation: {
    create: {
      success: 'allocation_create_success',
      error: 'allocation_create_error',
    },
    update: {
      success: 'allocation_update_success',
      error: 'allocation_update_error',
    },
    delete: {
      success: 'allocation_delete_success',
      error: 'allocation_delete_error',
    },
    execute: {
      success: 'allocation_execute_success',
      error: 'allocation_execute_error',
    },
    approve: {
      success: 'allocation_approve_success',
      error: 'allocation_approve_error',
    },
    reject: {
      success: 'allocation_reject_success',
      error: 'allocation_reject_error',
    },
  },
};
