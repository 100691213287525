import { CollectionPreferencesProps } from '@cloudscape-design/components';
import { TFunc } from '../types';

export const contentDisplayPreferenceI18nStrings = (
  t: TFunc,
): Partial<CollectionPreferencesProps.ContentDisplayPreference> => ({
  liveAnnouncementDndStarted: (position, total) =>
    t('live_announcement_dnd_started', { position, total }),
  liveAnnouncementDndDiscarded: t('live_announcement_dnd_discarded'),
  liveAnnouncementDndItemReordered: (initialPosition, currentPosition, total) =>
    initialPosition === currentPosition
      ? t('live_announcement_dnd_item_reordered_restore', {
          currentPosition,
          total,
        })
      : t('live_announcement_dnd_item_reordered', { currentPosition, total }),
  liveAnnouncementDndItemCommitted: (initialPosition, finalPosition, total) =>
    initialPosition === finalPosition
      ? t('live_announcement_dnd_item_committed_original', {
          initialPosition,
          total,
        })
      : t('live_announcement_dnd_item_committed', {
          initialPosition,
          finalPosition,
          total,
        }),
  dragHandleAriaDescription: t('drag_handle_aria_description'),
  dragHandleAriaLabel: t('drag_handle_aria_label'),
});
