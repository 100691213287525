/**
 * This function return the timeZoneOffset
 * @param timeZone
 * @param date
 * @returns
 */
export const getTimezoneOffset = (
  timeZone = 'America/Los_Angeles',
  date = new Date(),
): number => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (utcDate.getTime() - tzDate.getTime()) / 6e4;
};

/**
 * This function will format the date
 */
export const formatDate = (date?: Date) => {
  if (!date) {
    return '';
  }
  return new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
    timeStyle: 'long',
  }).format(new Date(date).getTime() * 1000);
};

// Maximum time for a day i.e. 24*60 = 1440;
const MAX_TIME = 1440;

/**
 * This function combines time with timezoneOffset
 * @param time "hh:mm"
 * @param timezoneOffset Number
 *
 * @returns time "hh:mm"
 */
export const convertTime = (time: string, timezoneOffset: number): string => {
  const timeInParts = time.split(':');
  const timeInMin = Number(timeInParts[0]) * 60 + Number(timeInParts[1]);
  let timeInTimezone = timeInMin + timezoneOffset;
  if (timeInTimezone > MAX_TIME) {
    timeInTimezone -= MAX_TIME;
  } else if (timeInTimezone < 0) {
    timeInTimezone = MAX_TIME + timeInTimezone;
  }
  return `${Math.floor(timeInTimezone / 60)
    .toString()
    .padStart(2, '0')}:${(timeInTimezone % 60).toString().padStart(2, '0')}`;
};
