import React, { Fragment, startTransition } from 'react';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { useTranslation } from 'react-i18next';
import Link, { LinkProps } from '@cloudscape-design/components/link';
import { useNavigate } from 'react-router-dom';
import Box from '@cloudscape-design/components/box';
import Grid from '@cloudscape-design/components/grid';
import Container from '@cloudscape-design/components/container';
import Icon from '@cloudscape-design/components/icon';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import { gettingStartedSection, resources } from './config';

import './landing-page.scss';

/**
 * This component renders the landing page
 */
const LandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * This function utilizes useNavigate hook to perform navigation.
   */
  const handleNavigation = (event: CustomEvent<LinkProps.FollowDetail>) => {
    if (event.detail.external) {
      return;
    }
    event.preventDefault();
    startTransition(() => navigate(event.detail.href as string));
  };

  return (
    <Box margin={{ bottom: 'l' }} data-testid="landing-page">
      <div className="landing-page-header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 10, xxs: 11 } },
              { offset: { l: 2, xxs: 1 }, colspan: { l: 10, xxs: 11 } },
            ]}
          >
            <Box fontWeight="light">
              <span className="category">{t('organization_name')}</span>
            </Box>
            <div className="title">
              <Box
                variant="h1"
                fontWeight="heavy"
                padding="n"
                fontSize="display-l"
                color="inherit"
              >
                {t('title')}
              </Box>
              <ColumnLayout columns={2}>
                <Box variant="p" fontWeight="light">
                  <span className="sub-title">{t('project_description')}</span>
                </Box>
              </ColumnLayout>
            </div>
          </Grid>
        </Box>
      </div>
      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            {
              colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
              offset: { l: 2, xxs: 1 },
            },
            {
              colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
              offset: { s: 0, xxs: 1 },
            },
          ]}
        >
          <SpaceBetween size="xxl">
            <div>
              <Box
                variant="h1"
                tagOverride="h2"
                padding={{ bottom: 's', top: 'n' }}
                fontWeight="light"
              >
                {t(gettingStartedSection.title)}
              </Box>
              <Container>
                <SpaceBetween size="s">
                  {gettingStartedSection.cards.map((section, index) => (
                    <div key={`getting-started-${index}`}>
                      <Link
                        fontSize="heading-m"
                        href={section.href}
                        onFollow={handleNavigation}
                      >
                        {t(section.name)}
                      </Link>
                      {section.description && (
                        <Box variant="p">{t(section.description)}</Box>
                      )}
                    </div>
                  ))}
                </SpaceBetween>
              </Container>
            </div>
          </SpaceBetween>
          <div className="side-content">
            <SpaceBetween size="xxl">
              <Container
                header={
                  <Header variant="h2">
                    {t(resources.title)} <Icon name="external" size="inherit" />
                  </Header>
                }
              >
                <ColumnLayout borders="horizontal" columns={1}>
                  {resources.cards.map((section, index) => (
                    <Fragment key={`resources-${index}`}>
                      <Link
                        external={Boolean(section.external)}
                        href={section.href}
                        onFollow={handleNavigation}
                      >
                        {t(section.name)}
                      </Link>
                    </Fragment>
                  ))}
                </ColumnLayout>
              </Container>
            </SpaceBetween>
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default LandingPage;
