import { Box } from '@cloudscape-design/components';
import Header from '@cloudscape-design/components/header';
import Tabs from '@cloudscape-design/components/tabs';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import QueryKey from 'src/api/QueryKey';
import { TaskSchedulingServiceApi } from 'src/api/TaskSchedulingServiceApi';

const AllocationDetailsPage = () => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const activeTabId = location.pathname.includes('version')
    ? 'version-details'
    : 'execution-details';

  const task = useQuery({
    queryKey: [QueryKey.GetTask, taskId],
    queryFn: () => TaskSchedulingServiceApi.getTask({ taskId }),
    enabled: Boolean(taskId),
  });

  const handleTabChange = () => {};

  return (
    <Box margin={{ top: 'm' }} data-testid="execution-details-page">
      <Header variant="h1">{task.data?.task?.name}</Header>
      <Tabs
        tabs={[
          {
            label: t('execution_details'),
            id: 'execution-details',
            href: `/allocation/details/${taskId}`,
          },
          {
            label: t('version_details'),
            id: 'version-details',
            disabled: true,
          },
        ]}
        onChange={handleTabChange}
        activeTabId={activeTabId}
      />
      <Outlet />
    </Box>
  );
};

export default AllocationDetailsPage;
