import { WizardProps } from '@cloudscape-design/components';
import { TFunc } from '../types';

export const i18nStrings = (t: TFunc): WizardProps.I18nStrings => ({
  stepNumberLabel: (stepNumber) => t('step_number_label', { stepNumber }),
  collapsedStepsLabel: (stepNumber, stepsCount) =>
    t('collapsed_steps_label', { stepNumber, stepsCount }),
  skipToButtonLabel: (step) => t('skip_to_button_label', { title: step.title }),
  navigationAriaLabel: t('wizard_navigation_aria_label'),
  cancelButton: t('cancel'),
  previousButton: t('previous'),
  nextButton: t('next'),
  submitButton: t('create'),
  optional: t('optional'),
});
