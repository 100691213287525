import React, {
  lazy,
  startTransition,
  useEffect,
  useReducer,
  useState,
} from 'react';
import Box from '@cloudscape-design/components/box';
import Button from '@cloudscape-design/components/button';
import Container from '@cloudscape-design/components/container';
import Header from '@cloudscape-design/components/header';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';
import Select from '@cloudscape-design/components/select';
import { useTranslation } from 'react-i18next';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Multiselect from '@cloudscape-design/components/multiselect';
import { Action, Resource } from '@amzn/fae-auth-service';
import { ContentLayout } from '@cloudscape-design/components';
import { reducer } from '../../common/utils/reducer';
import { getName, getScenario } from '../../common/utils/filters';
import {
  getAllocationConfigurations,
  getBusinessOptions,
  getCountryOptions,
  getMethodologyOptions,
  getQueryKey,
  getRegionOptions,
} from '../create-allocation-page/config';
import { Config, defaultConfig } from 'src/common/types/Config';
import { CreateSchedule } from 'src/common/components/CreateSchedule';
import { Schedule, defaultSchedule } from 'src/common/types/Schedule';
import { PageAction } from 'src/common/types/PageAction';
import { InputChangeEvent, SelectChangeEvent } from 'src/common/types/Events';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { Notification } from 'src/common/constants/Notification';
import { useMetrics } from 'src/common/provider/MetricsProvider';
import { Page } from 'src/common/types/Page';
import { useCreateDisabled } from 'src/common/hooks/useCreateDisabled';
import { TaskSchedulingServiceApi } from 'src/api/TaskSchedulingServiceApi';
import QueryKey from 'src/api/QueryKey';
import {
  getCreateTaskRequest,
  getDriverTaskInput,
  getUpdateTaskRequest,
} from 'src/common/utils/task';
import { WorkdaysList } from 'src/common/constants/workday';
import { convertTime, getTimezoneOffset } from 'src/common/utils/date';
import { AuthServiceApi } from 'src/api/AuthServiceApi';
import { DropdownStatus } from 'src/common/types';

const ConfirmationModal = lazy(
  () => import('src/common/components/ConfirmationModal'),
);

const CreateDriverPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { taskId } = useParams();
  const { addNotification } = useNotificationContext();
  const metrics = useMetrics();
  const queryClient = useQueryClient();

  const [config, dispatch] = useReducer(reducer, defaultConfig);
  const [schedule, setSchedule] = useState<Schedule>(defaultSchedule);
  const [confirmModal, setConfirmModal] = useState(false);
  const action: Action = location.pathname.includes('update')
    ? Action.UPDATE
    : Action.CREATE;

  const task = useQuery({
    queryKey: [QueryKey.GetTask, taskId],
    queryFn: () => TaskSchedulingServiceApi.getTask({ taskId }),
    enabled: Boolean(taskId),
  });

  const createTask = useMutation({
    mutationFn: TaskSchedulingServiceApi.createTask,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const updateTask = useMutation({
    mutationFn: TaskSchedulingServiceApi.updateTask,
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const taskConfig = useQuery({
    queryKey: getQueryKey(action, config.business?.value),
    queryFn: () =>
      AuthServiceApi.getPermissionsByBusinessRequest({
        action,
        resource: Resource.TASK,
        business: config.business?.value,
      }),
    enabled: Boolean(config.business?.value),
    select: (data) => getAllocationConfigurations(data.authorizedCombinations),
  });

  useEffect(() => {
    if (!task.data) {
      return;
    }
    const driver = task.data?.task;
    dispatch({
      type: 'UPDATE',
      payload: {
        name: driver?.name,
        description: driver?.description,
        methodology: {
          value: driver?.input?.driverTaskInput?.methodology,
          label: driver?.input?.driverTaskInput?.methodology,
        },
        business: {
          value: driver?.input?.driverTaskInput?.business,
          label: driver?.input?.driverTaskInput?.business,
        },
        region: {
          value: driver?.input?.driverTaskInput?.region,
          label: driver?.input?.driverTaskInput?.region,
        },
        country: driver?.input?.driverTaskInput?.country?.map((value) => ({
          value,
          label: value,
        })),
        scenario: {
          value: driver?.input?.driverTaskInput?.scenario,
          label: driver?.input?.driverTaskInput?.scenario,
        },
      },
    });

    const time =
      driver?.schedule?.scheduleTime &&
      convertTime(driver.schedule.scheduleTime, -getTimezoneOffset());

    setSchedule({
      enable: Boolean(driver?.schedule),
      time: time ? { label: time, value: time } : null,
      workdayEnd:
        WorkdaysList.find(
          (workday) =>
            workday.value === driver?.schedule?.workdayEnd?.toString(),
        ) ?? null,
      workdayStart:
        WorkdaysList.find(
          (workday) =>
            workday.value === driver?.schedule?.workdayStart?.toString(),
        ) ?? null,
    });
  }, [task.data]);

  const handleFilterInputChange =
    (filterKey: keyof Config) => (event: InputChangeEvent) => {
      dispatch({
        type: 'UPDATE',
        payload: { [filterKey]: event.detail.value },
      });
    };

  const handleFilterSelectChange =
    (filterKey: keyof Config) => (event: SelectChangeEvent) => {
      dispatch({
        type: `UPDATE-${filterKey.toUpperCase()}`,
        payload: { [filterKey]: event.detail.selectedOption },
      });
    };

  const handleRegionChange = (event: SelectChangeEvent) => {
    dispatch({
      type: 'UPDATE-REGION',
      payload: {
        region: event.detail.selectedOption,
        country: getCountryOptions(
          config.methodology?.value,
          event.detail.selectedOption.value,
          taskConfig.data,
        ),
      },
    });
  };

  const handleScheduleChange = (schedule: Schedule) => {
    setSchedule({ ...schedule });
  };

  const handleReset = () => {
    setSchedule(defaultSchedule);
    dispatch({ type: 'RESET' });
  };

  const toggleConfirmModal = () => {
    startTransition(() => setConfirmModal(!confirmModal));
  };

  const handleConfirm = async () => {
    const startTime = performance.now();
    try {
      action === Action.UPDATE
        ? await updateTask.mutateAsync(
            getUpdateTaskRequest(
              taskId,
              config.description,
              getDriverTaskInput(config),
              schedule,
            ),
          )
        : await createTask.mutateAsync(
            getCreateTaskRequest(config, schedule, getDriverTaskInput(config)),
          );
      addNotification({
        type: 'success',
        content:
          action === Action.UPDATE
            ? t(Notification.driver.update.success)
            : t(Notification.driver.create.success),
      });
      metrics.publishCounter(`${Page.Driver}.${action}`, PageAction.Success, 1);
      startTransition(() => navigate('/driver/manage'));
    } catch (error: any) {
      addNotification({
        type: 'error',
        header:
          action === Action.UPDATE
            ? t(Notification.driver.update.error)
            : t(Notification.driver.create.error),
        content: error?.message,
      });
      metrics.publishCounter(`${Page.Driver}.${action}`, PageAction.Failure, 1);
    }
    const endTime = performance.now();
    metrics.publishCounter(Page.Driver, action, 1);
    metrics.publishTime(`${Page.Driver}.${action}`, endTime - startTime);
    toggleConfirmModal();
  };

  const isCreateDisabled = useCreateDisabled(config);

  return (
    <>
      {confirmModal && (
        <ConfirmationModal
          visible={confirmModal}
          header={
            action === Action.CREATE ? t('create_driver') : t('update_driver')
          }
          onConfirm={handleConfirm}
          onDismiss={toggleConfirmModal}
          config={{ ...config, name: getName(config) }}
          schedule={schedule}
        />
      )}
      <ContentLayout>
        <SpaceBetween
          direction="vertical"
          size="m"
          data-testid="create-driver-page"
        >
          <Container
            header={<Header variant="h2">{t('configuration')}</Header>}
          >
            <ColumnLayout columns={4}>
              <FormField label={t('name')}>
                <Input
                  data-testid="name"
                  value={getName(config)}
                  placeholder={t('name_placeholder')}
                  disabled
                  readOnly
                />
              </FormField>
              <FormField
                label={t('description')}
                constraintText={t('description_constraint')}
              >
                <Input
                  data-testid="description"
                  value={config.description}
                  onChange={handleFilterInputChange('description')}
                  placeholder={t('description_placeholder')}
                />
              </FormField>
              <FormField label={t('business')}>
                <Select
                  data-testid="business"
                  options={getBusinessOptions()}
                  onChange={handleFilterSelectChange('business')}
                  selectedOption={config.business}
                  placeholder={t('business_placeholder')}
                />
              </FormField>
              <FormField label={t('methodology')}>
                <Select
                  data-testid="methodology"
                  loadingText={taskConfig.isLoading ? t('table_loading') : ''}
                  statusType={
                    taskConfig.isLoading
                      ? DropdownStatus.LOADING
                      : DropdownStatus.FINISHED
                  }
                  options={getMethodologyOptions(taskConfig.data)}
                  onChange={handleFilterSelectChange('methodology')}
                  selectedOption={config.methodology}
                  placeholder={t('methodology_placeholder')}
                  disabled={action === Action.UPDATE || !config.business}
                />
              </FormField>
              <FormField label={t('region')}>
                <Select
                  data-testid="region"
                  loadingText={taskConfig.isLoading ? t('table_loading') : ''}
                  statusType={
                    taskConfig.isLoading
                      ? DropdownStatus.LOADING
                      : DropdownStatus.FINISHED
                  }
                  options={getRegionOptions(
                    config.methodology?.value,
                    taskConfig.data,
                  )}
                  onChange={handleRegionChange}
                  selectedOption={config.region}
                  placeholder={t('region_placeholder')}
                  disabled={action === Action.UPDATE || !config.methodology}
                />
              </FormField>
              <FormField label={t('country')}>
                <Multiselect
                  data-testid="country"
                  loadingText={taskConfig.isLoading ? t('table_loading') : ''}
                  statusType={
                    taskConfig.isLoading
                      ? DropdownStatus.LOADING
                      : DropdownStatus.FINISHED
                  }
                  options={getCountryOptions(
                    config.methodology?.value,
                    config.region?.value,
                    taskConfig.data,
                  )}
                  selectedOptions={config.country}
                  placeholder={t('country_placeholder')}
                  disabled
                />
              </FormField>
              <FormField label={t('scenario')}>
                <Select
                  data-testid="scenario"
                  options={getScenario()}
                  onChange={handleFilterSelectChange('scenario')}
                  selectedOption={config.scenario}
                  placeholder={t('scenario_placeholder')}
                  disabled={action === Action.UPDATE || !config.region}
                />
              </FormField>
            </ColumnLayout>
          </Container>
          <CreateSchedule
            schedule={schedule}
            onScheduleChange={handleScheduleChange}
          />
          <Box float="right">
            <SpaceBetween direction="horizontal" size="m">
              <Button data-testid="reset" onClick={handleReset}>
                {t('reset')}
              </Button>
              <Button
                data-testid="create"
                variant="primary"
                disabled={isCreateDisabled}
                onClick={toggleConfirmModal}
              >
                {action === Action.CREATE ? t('create') : t('update')}
              </Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      </ContentLayout>
    </>
  );
};

export default CreateDriverPage;
