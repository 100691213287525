import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

interface NotificationContextType {
  addNotification(notification: FlashbarProps.MessageDefinition): void;
  clearNotification(): void;
  notification: FlashbarProps.MessageDefinition;
}

/**
 * React context for Notifications
 */
const NotificationContext = createContext<NotificationContextType>({
  notification: {},
  addNotification: () => {},
  clearNotification: () => {},
});

/**
 * Props for NotificationProvider
 */
interface NotificationProviderProps {
  children: ReactNode;
}

/**
 * Wrapper to allow notifications in application
 */
export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notification, setNotification] =
    useState<FlashbarProps.MessageDefinition>({});

  const addNotification = useCallback(
    (notification: FlashbarProps.MessageDefinition) => {
      setNotification(notification);
    },
    [],
  );

  const clearNotification = useCallback(() => setNotification({}), []);

  return (
    <NotificationContext.Provider
      value={{
        notification,
        addNotification,
        clearNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

/**
 * React hook to use notification context
 */
export const useNotificationContext = () => useContext(NotificationContext);
