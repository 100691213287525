import React from 'react';
import { Link } from '@cloudscape-design/components';
import { TableProps } from '@cloudscape-design/components/table';
import { ReportMetadata } from '@amzn/allocations-service';
import { TFunc } from 'src/common/types';

export const columnDefinitions = (
  t: TFunc,
): TableProps.ColumnDefinition<ReportMetadata>[] => [
  {
    id: 'reportName',
    header: t('name'),
    cell: (item: ReportMetadata) => item.reportName,
    sortingField: 'reportName',
  },
  {
    id: 'methodology',
    header: t('methodology'),
    cell: (item: ReportMetadata) => item.methodology,
    sortingField: 'methodology',
  },
  {
    id: 'business',
    header: t('quicksight_account'),
    cell: (item: ReportMetadata) => item.business,
    sortingField: 'business',
  },
  {
    id: 'linkToReport',
    header: t('link'),
    cell: (item: ReportMetadata) => (
      <Link external href={item.linkToReport}>
        {item.linkToReport}
      </Link>
    ),
    sortingField: 'linkToReport',
  },
];
