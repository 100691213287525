import Box from '@cloudscape-design/components/box';
import React, { FC } from 'react';

interface TableEmptyStateProps {
  subtitle: string;
  title: string;
}

export const TableEmptyState: FC<TableEmptyStateProps> = (props) => (
  <Box textAlign="center" color="inherit" data-testid="empty-table">
    <Box variant="strong" textAlign="center" color="inherit">
      {props.title}
    </Box>
    <Box variant="p" padding={{ bottom: 's' }} color="inherit">
      {props.subtitle}
    </Box>
  </Box>
);
