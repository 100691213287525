import { startTransition, useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

export const useNavigationBlocker = () => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (!isBlocking) {
      return blocker.proceed?.();
    }
    startTransition(() => setConfirmationModal(blocker.state === 'blocked'));
  }, [blocker, blocker.state, isBlocking]);

  const onNavigationCancel = () => {
    blocker.reset?.();
    setConfirmationModal(false);
  };

  const onNavigationConfirm = () => {
    blocker.proceed?.();
  };

  return {
    confirmationModal,
    onNavigationCancel,
    onNavigationConfirm,
    setIsBlocking,
  };
};
