import React from 'react';
import { Link } from '@cloudscape-design/components';
import { TableProps } from '@cloudscape-design/components/table';
import { StaticDriverMappingMetadata } from '@amzn/allocations-service';
import { TFunc } from 'src/common/types';
import { formatDate } from 'src/common/utils/date';

export const columnDefinitions = (
  t: TFunc,
): TableProps.ColumnDefinition<StaticDriverMappingMetadata>[] => [
  {
    id: 'mappingName',
    header: t('name'),
    cell: (item: StaticDriverMappingMetadata) => (
      <Link external href={item.linkToMapping}>
        {item.mappingName}
      </Link>
    ),
    sortingField: 'mappingName',
  },
  {
    id: 'methodology',
    header: t('methodology'),
    cell: (item: StaticDriverMappingMetadata) => item.methodology,
    sortingField: 'methodology',
  },
  {
    id: 'lastUpdateTimestamp',
    header: t('last_updated_date'),
    cell: (item: StaticDriverMappingMetadata) =>
      formatDate(item.lastUpdateTimestamp),
    sortingField: 'lastUpdateTimestamp',
  },
];
