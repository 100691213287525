import { useMemo } from 'react';
import { Config } from '../types/Config';

export const hasErrorInConfig = (config: Config) =>
  !config.description ||
  config.description.length > 100 ||
  !config.methodology ||
  !config.business ||
  !config.region ||
  !config.country ||
  !config.country.length ||
  !config.scenario;

export const useCreateDisabled = (config: Config) =>
  useMemo(() => hasErrorInConfig(config), [config]);
