import Spinner from '@cloudscape-design/components/spinner';
import AppLayout, {
  AppLayoutProps,
} from '@cloudscape-design/components/app-layout';
import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { NavBar } from './Navbar';
import { Breadcrumbs } from './Breadcrumbs';
import { Notification } from 'src/common/components/Notification';
import { useUserAlias } from 'src/common/hooks/useUserAlias';
import { EvidentlyServiceApi } from 'src/api/EvidentlyServiceApi';
import QueryKey from 'src/api/QueryKey';

const getContentType = (pathname: string): AppLayoutProps.ContentType => {
  switch (pathname) {
    case '/driver/manage':
    case '/allocation/manage':
    case '/general/mappings':
    case '/general/reports':
      return 'table';
    case '/allocation/create':
      return 'wizard';
    default:
      return 'default';
  }
};

/**
 * React component to authenticate users
 */
export const PrivateRoute = (): JSX.Element => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { alias } = useUserAlias();
  const location = useLocation();
  const [contentType, setContentType] =
    useState<AppLayoutProps.ContentType>('default');

  // Prefetch feature flags
  useQuery({
    queryKey: [QueryKey.EvaluateFeature],
    queryFn: () => EvidentlyServiceApi.getFeatureMap(alias as string),
    enabled: route === 'authenticated' && Boolean(alias),
  });

  const isLandingPage = location.pathname === '/';

  // Dispatch user info
  // Ref: https://code.amazon.com/packages/CathodeVersionsJavaScript/trees/mainline
  useEffect(() => {
    if (route !== 'authenticated') {
      return;
    }
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: alias,
      },
    });
    document.dispatchEvent(authenticatedUserEvent);
  }, [route, alias]);

  useEffect(() => {
    setContentType(getContentType(location.pathname));
  }, [location.pathname]);

  return (
    <>
      {route === 'authenticated' && (
        <>
          <NavBar />
          <AppLayout
            contentType={contentType}
            disableContentPaddings={isLandingPage}
            breadcrumbs={isLandingPage ? undefined : <Breadcrumbs />}
            content={<Outlet />}
            toolsHide={true}
            navigationHide={true}
            notifications={<Notification />}
          />
        </>
      )}
      {route !== 'authenticated' && <Spinner data-testid="page-loader" />}
    </>
  );
};

export default PrivateRoute;
