import Checkbox from '@cloudscape-design/components/checkbox';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import Container from '@cloudscape-design/components/container';
import FormField from '@cloudscape-design/components/form-field';
import Header from '@cloudscape-design/components/header';
import Select from '@cloudscape-design/components/select';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ScheduleTimeList, WorkdaysList } from '../constants/workday';
import { Schedule } from '../types';
import { CheckboxChangeEvent, SelectChangeEvent } from '../types/Events';

interface CreateScheduleProps {
  onScheduleChange(schedule: Schedule): void;
  schedule: Schedule;
  columns?: number;
}

export const CreateSchedule: FC<CreateScheduleProps> = (props) => {
  const { t } = useTranslation();

  const handleEnableSchedule = (event: CheckboxChangeEvent) => {
    props.onScheduleChange({ ...props.schedule, enable: event.detail.checked });
  };

  const handleWorkdayStart = (event: SelectChangeEvent) => {
    let workdayEnd = null;
    if (
      props.schedule.workdayEnd?.value &&
      event.detail.selectedOption?.value &&
      props.schedule.workdayEnd.value >= event.detail.selectedOption.value
    ) {
      workdayEnd = props.schedule.workdayEnd;
    }
    props.onScheduleChange({
      ...props.schedule,
      workdayStart: event.detail.selectedOption,
      workdayEnd,
    });
  };

  const handleChange =
    (scheduleKey: keyof Schedule) => (event: SelectChangeEvent) => {
      props.onScheduleChange({
        ...props.schedule,
        [scheduleKey]: event.detail.selectedOption,
      });
    };

  return (
    <Container header={<Header variant="h2">{t('schedule')}</Header>}>
      <SpaceBetween size="m">
        <Checkbox
          data-testid="enable-schedule"
          checked={props.schedule.enable}
          onChange={handleEnableSchedule}
        >
          {t('enable_schedule')}
        </Checkbox>
        <ColumnLayout columns={props.columns ?? 4}>
          <FormField label={t('frequency')}>
            <Select
              data-testid="frequency"
              options={[]}
              selectedOption={{ label: t('monthly'), value: 'monthly' }}
              disabled={true}
            />
          </FormField>
          <FormField label={t('workday_start')}>
            <Select
              data-testid="workday-start"
              options={WorkdaysList}
              selectedOption={props.schedule.workdayStart}
              onChange={handleWorkdayStart}
              placeholder={t('workday_placeholder')}
              disabled={!props.schedule.enable}
            />
          </FormField>
          <FormField label={t('workday_end')}>
            <Select
              data-testid="workday-end"
              options={WorkdaysList.filter(
                (workday) =>
                  props.schedule.workdayStart?.value &&
                  workday.value >= props.schedule.workdayStart?.value,
              )}
              selectedOption={props.schedule.workdayEnd}
              onChange={handleChange('workdayEnd')}
              placeholder={t('workday_placeholder')}
              disabled={!props.schedule.enable}
            />
          </FormField>
          <FormField label={t('time', { timezone: 'PT' })}>
            <Select
              data-testid="time"
              options={ScheduleTimeList}
              selectedOption={props.schedule.time}
              onChange={handleChange('time')}
              placeholder={t('time_placeholder')}
              disabled={!props.schedule.enable}
            />
          </FormField>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );
};
