export enum PageAction {
  Approve = 'Approve',
  Create = 'Create',
  Delete = 'Delete',
  Execute = 'Execute',
  Failure = 'Failure',
  Reject = 'Reject',
  Success = 'Success',
  Update = 'Update',
}
