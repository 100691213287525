import CollectionPreferences, {
  CollectionPreferencesProps,
} from '@cloudscape-design/components/collection-preferences';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunc } from '../types';
import { contentDisplayPreferenceI18nStrings } from '../constants/contentDisplayPreferenceI18nStrings';
import { CollectionPreferenceEvent } from '../types/Events';

const pageSizeOptions = (t: TFunc) => [
  { value: 10, label: t('page_size_label', { pageSize: 10 }) },
  { value: 30, label: t('page_size_label', { pageSize: 30 }) },
  { value: 50, label: t('page_size_label', { pageSize: 50 }) },
];

interface TablePreferencesProps {
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
  preferences: CollectionPreferencesProps.Preferences;
  setPreferences(preferences: CollectionPreferencesProps.Preferences): void;
}

export const TablePreferences: FC<TablePreferencesProps> = (props) => {
  const { t } = useTranslation();

  const handleConfirm = ({ detail }: CollectionPreferenceEvent) => {
    props.setPreferences(detail);
  };

  return (
    <CollectionPreferences
      title={t('preferences')}
      confirmLabel={t('confirm')}
      cancelLabel={t('cancel')}
      preferences={props.preferences}
      onConfirm={handleConfirm}
      pageSizePreference={{
        title: t('page_size'),
        options: pageSizeOptions(t),
      }}
      wrapLinesPreference={{
        label: t('wrap_lines'),
        description: t('wrap_lines_description'),
      }}
      stripedRowsPreference={{
        label: t('striped_rows'),
        description: t('striped_rows_description'),
      }}
      contentDensityPreference={{
        label: t('content_density'),
        description: t('content_density_description'),
      }}
      contentDisplayPreference={{
        title: t('content_display'),
        description: t('content_display_description'),
        options: props.contentDisplayOptions,
        ...contentDisplayPreferenceI18nStrings,
      }}
    />
  );
};
