import React from 'react';
import { Task } from '@amzn/taskscheduling-service';
import { TableProps } from '@cloudscape-design/components/table';
import StatusIndicator from '@cloudscape-design/components/status-indicator';
import { TFunc } from 'src/common/types';
import { formatDate } from 'src/common/utils/date';
import { TaskWithStatus } from 'src/common/types/TaskWithStatus';
import { eventStatusVsStatusIndicatorTypeMap } from 'src/common/utils/table';
import { EventStatus } from 'src/common/types/EventStatus';

export const columnDefinitions = (
  t: TFunc,
): TableProps.ColumnDefinition<Task>[] => [
  {
    id: 'name',
    header: t('name'),
    cell: (item: Task) => item.name,
    sortingField: 'name',
  },
  {
    id: 'description',
    header: t('description'),
    cell: (item: Task) => item.description,
    sortingField: 'description',
  },
  {
    id: 'creationDate',
    header: t('created_date'),
    cell: (item: Task) => formatDate(item.creationDate),
    sortingField: 'creationDate',
  },
  {
    id: 'creator',
    header: t('created_by'),
    cell: (item: Task) => item.creator,
    sortingField: 'creator',
  },
  {
    id: 'lastUpdateDate',
    header: t('last_updated_date'),
    cell: (item: Task) => formatDate(item.lastUpdateDate),
    sortingField: 'lastUpdateDate',
  },
  {
    id: 'lastUpdateBy',
    header: t('last_updated_by'),
    cell: (item: Task) => item.lastUpdateBy,
    sortingField: 'lastUpdateBy',
  },
  {
    id: 'status',
    header: t('status'),
    cell: (item: TaskWithStatus) => (
      <StatusIndicator
        type={
          eventStatusVsStatusIndicatorTypeMap[
            item.status ?? EventStatus.NOT_STARTED
          ]
        }
      >
        {item.status ?? EventStatus.NOT_STARTED}
      </StatusIndicator>
    ),
    sortingField: 'status',
  },
];
