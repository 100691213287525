export default {
  CreateTask: 'CreateTask',
  DeleteTask: 'DeleteTask',
  EvaluateFeature: 'EvaluateFeature',
  ExecuteTask: 'ExecuteTask',
  GetDefaultValues: 'GetDefaultValues',
  GetDimensions: 'getDimensions',
  GetDriverMappings: 'GetDriverMappings',
  GetExecutionEvents: 'GetExecutionEvents',
  GetHierarchyNamesForDimension: 'GetHierarchyNamesForDimension',
  GetPermissions: 'GetPermissions',
  GetPermissionsByBusiness: 'GetPermissionsByBusiness',
  GetReportMetadata: 'GetReportMetadata',
  GetStaticDriverMetadata: 'GetStaticDriverMetadata',
  GetStaticDrivers: 'getStaticDrivers',
  GetTask: 'GetTask',
  GetTaskExecutionState: 'GetTaskExecutionState',
  HasPermission: 'HasPermission',
  ListTaskExecutions: 'ListTaskExecutions',
  ListTasks: 'ListTasks',
  ListTasksWithLatestExecutionState: 'ListTasksWithLatestExecutionState',
  PutApprovalStatus: 'PutApprovalStatus',
  PutExecutionEvent: 'PutExecutionEvent',
  UpdateTask: 'UpdateTask',
};
