export enum EventStatus {
  APPROVED = 'APPROVED',
  FAILURE = 'FAILURE',
  PENDING_REVIEW = 'PENDING_REVIEW',
  REJECTED = 'REJECTED',
  RUNNING = 'RUNNING',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS',
  NOT_STARTED = 'NOT_STARTED',
}
