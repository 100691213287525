import { MultiselectProps } from '@cloudscape-design/components';
import { SelectProps } from '@cloudscape-design/components/select';
import { DriverMapping } from './DriverMapping';

export interface Config {
  business: SelectProps.Option | null;
  country: MultiselectProps.Option[];
  description: string;
  methodology: SelectProps.Option | null;
  name: string;
  region: SelectProps.Option | null;
  scenario: SelectProps.Option | null;
  error?: {
    allocationConfigurationStep?: string;
    scheduleStep?: string;
    driverMappingStep?: string;
    reviewStep?: string;
  };
  modelingInput?: Record<string, string>;
  driverMappings?: DriverMapping[];
}

export const defaultConfig: Config = {
  business: null,
  country: [],
  description: '',
  methodology: null,
  name: '',
  region: null,
  scenario: null,
};
