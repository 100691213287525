import { SelectProps } from '@cloudscape-design/components/select';

export interface Schedule {
  enable: boolean;
  time: SelectProps.Option | null;
  workdayEnd: SelectProps.Option | null;
  workdayStart: SelectProps.Option | null;
}

export const defaultSchedule: Schedule = {
  enable: false,
  time: null,
  workdayEnd: null,
  workdayStart: null,
};
