import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { TFunc } from 'src/common/types';

export const filteringProperties = (t: TFunc): PropertyFilterProperty[] => [
  {
    propertyLabel: t('name'),
    key: 'name',
    groupValuesLabel: t('group_value_label', { label: t('name') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('description'),
    key: 'description',
    groupValuesLabel: t('group_value_label', { label: t('description') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('created_date'),
    key: 'creationDate',
    groupValuesLabel: t('group_value_label', { label: t('created_date') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('created_by'),
    key: 'creator',
    groupValuesLabel: t('group_value_label', { label: t('created_by') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('last_updated_date'),
    key: 'lastUpdateDate',
    groupValuesLabel: t('group_value_label', { label: t('last_updated_date') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('last_updated_by'),
    key: 'lastUpdateBy',
    groupValuesLabel: t('group_value_label', { label: t('last_updated_by') }),
    operators: [':', '!:', '=', '!='],
  },
];
