import React, { ReactNode, useMemo, useState } from 'react';
import {
  PropertyFilterProperty,
  useCollection,
} from '@amzn/awsui-collection-hooks';
import { CollectionPreferencesProps } from '@cloudscape-design/components/collection-preferences';
import Header from '@cloudscape-design/components/header';
import Pagination from '@cloudscape-design/components/pagination';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import TableComponent, {
  TableProps as TableComponentProps,
} from '@cloudscape-design/components/table';
import { useTranslation } from 'react-i18next';
import propertyFilterI18nStrings from '../constants/propertyFilterI18nStrings';
import { TableChangeEvent } from '../types/Events';
import { TableEmptyState } from './TableEmptyState';
import { TableNoMatchState } from './TableNoMatchState';
import { TablePreferences } from './TablePreferences';
import {
  defaultPreference,
  getTextFilterCounterText,
} from 'src/common/utils/table';

interface TableProps<T> {
  buttons?: ReactNode;
  columnDefinitions: TableComponentProps.ColumnDefinition<T>[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
  data: T[];
  empty?: { title: string; description: string };
  filteringProperties?: PropertyFilterProperty[];
  header?: string;
  loading?: boolean;
  onSelectionChange?(items: T[]): void;
  pagination?: boolean;
  preferences?: boolean;
  selectedItems?: T[];
  selectionType?: TableComponentProps.SelectionType;
  sortingState?: TableComponentProps.SortingState<T>;
  testid: string;
  trackBy?: string;
  variant?: TableComponentProps.Variant;
}

export const Table = <T extends unknown>(props: TableProps<T>) => {
  const { t } = useTranslation();

  const defaultPreferences = useMemo(
    () => ({
      ...defaultPreference,
      contentDisplay: props.contentDisplayOptions.map((option) => ({
        id: option.id,
        visible: true,
      })),
    }),
    [props.contentDisplayOptions],
  );

  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>(defaultPreferences);

  const {
    items,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(props.data, {
    propertyFiltering: {
      filteringProperties: props.filteringProperties ?? [],
      empty: props.empty ? (
        <TableEmptyState
          title={props.empty.title}
          subtitle={props.empty.description}
        />
      ) : undefined,
      noMatch: <TableNoMatchState />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      ...(props.sortingState && { defaultState: props.sortingState }),
    },
    selection: {},
  });

  const handleSelectionChange = ({ detail }: TableChangeEvent<T>) => {
    props.onSelectionChange
      ? props.onSelectionChange(detail.selectedItems)
      : undefined;
  };

  return (
    <TableComponent
      {...collectionProps}
      data-testid={props.testid}
      items={items}
      selectedItems={props.selectedItems ?? []}
      onSelectionChange={handleSelectionChange}
      columnDefinitions={props.columnDefinitions}
      selectionType={props.selectionType}
      columnDisplay={preferences.contentDisplay}
      variant={props.variant ?? 'container'}
      header={
        props.header ? (
          <Header
            counter={
              props.selectedItems?.length
                ? `(${props.selectedItems.length}/${props.data.length})`
                : `(${props.data.length})`
            }
            actions={props.buttons}
          >
            {props.header}
          </Header>
        ) : undefined
      }
      pagination={
        props.pagination ? <Pagination {...paginationProps} /> : undefined
      }
      filter={
        props.filteringProperties ? (
          <PropertyFilter
            i18nStrings={propertyFilterI18nStrings(t)}
            {...propertyFilterProps}
            countText={getTextFilterCounterText(filteredItemsCount, t)}
            expandToViewport={true}
          />
        ) : undefined
      }
      preferences={
        props.preferences ? (
          <TablePreferences
            contentDisplayOptions={props.contentDisplayOptions}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        ) : undefined
      }
      wrapLines={preferences.wrapLines}
      stripedRows={preferences.stripedRows}
      contentDensity={preferences.contentDensity}
      stickyColumns={preferences.stickyColumns}
      resizableColumns={true}
      trackBy={props?.trackBy}
      loading={props.loading}
      loadingText={t('table_loading')}
    />
  );
};
