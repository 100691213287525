import { TFunc } from '../types';

export default (t: TFunc) => ({
  filteringAriaLabel: t('filtering_aria_label'),
  dismissAriaLabel: t('dismiss'),
  clearAriaLabel: t('clear'),

  filteringPlaceholder: t('filtering_placeholder'),
  groupValuesText: t('values'),
  groupPropertiesText: t('properties'),
  operatorsText: t('operators'),

  operationAndText: t('and'),
  operationOrText: t('or'),

  operatorLessText: t('less_than'),
  operatorLessOrEqualText: t('less_than_or_equal'),
  operatorGreaterText: t('greater_than'),
  operatorGreaterOrEqualText: t('greater_than_or_equal'),
  operatorContainsText: t('contains'),
  operatorDoesNotContainText: t('not_contain'),
  operatorEqualsText: t('equals'),
  operatorDoesNotEqualText: t('not_equals'),

  editTokenHeader: t('edit_filter'),
  propertyText: t('property'),
  operatorText: t('operator'),
  valueText: t('value'),
  cancelActionText: t('cancel'),
  applyActionText: t('apply'),
  allPropertiesLabel: t('all_properties'),

  tokenLimitShowMore: t('show_more'),
  tokenLimitShowFewer: t('show_fewer'),
  clearFiltersText: t('clear_filters'),
  enteredTextLabel: (text: string) => t('entered_text_label', { text }),
});
