import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ReportMetadata } from '@amzn/allocations-service';
import { columnDefinitions } from './config/table-config';
import { filteringProperties } from './config/filter-property';
import { Table } from 'src/common/components/Table';
import { getContentDisplayOptions } from 'src/common/utils/table';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import QueryKey from 'src/api/QueryKey';

const ReportsPage = () => {
  const { t } = useTranslation();
  const columnDef = useMemo(() => columnDefinitions(t), [t]);
  const reports = useQuery({
    queryKey: [QueryKey.GetReportMetadata],
    queryFn: () => AllocationServiceApi.getReportMetadata({}),
  });

  return (
    <div data-testid="reports-page">
      <Table<ReportMetadata>
        variant="full-page"
        columnDefinitions={columnDef}
        contentDisplayOptions={getContentDisplayOptions(columnDef)}
        data={reports.data?.reports ?? []}
        testid="reports-table"
        header={t('reports')}
        pagination={true}
        preferences={true}
        empty={{
          title: t('empty_reports'),
          description: t('empty_reports_description'),
        }}
        filteringProperties={filteringProperties(t)}
        loading={reports.isFetching}
      />
    </div>
  );
};

export default ReportsPage;
