import Container from '@cloudscape-design/components/container';
import FormField from '@cloudscape-design/components/form-field';
import Input from '@cloudscape-design/components/input';
import Multiselect from '@cloudscape-design/components/multiselect';
import Select from '@cloudscape-design/components/select';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Action, Resource } from '@amzn/fae-auth-service';
import { useQuery } from '@tanstack/react-query';
import { SpaceBetween } from '@cloudscape-design/components';
import {
  getAllocationConfigurations,
  getBusinessOptions,
  getCountryOptions,
  getMethodologyOptions,
  getQueryKey,
  getRegionOptions,
} from '../config';
import { Config } from 'src/common/types/Config';
import { InputChangeEvent, SelectChangeEvent } from 'src/common/types/Events';
import { getName, getScenario } from 'src/common/utils/filters';
import { AuthServiceApi } from 'src/api/AuthServiceApi';
import { DropdownStatus } from 'src/common/types';

interface AllocationMethodologyStepInterface {
  config: Config;
  onConfigChange(action: { type: string; payload?: Partial<Config> }): void;
}

export const AllocationMethodologyStep: FC<
  AllocationMethodologyStepInterface
> = (props) => {
  const { t } = useTranslation();
  const action: Action = location.pathname.includes('update')
    ? Action.UPDATE
    : Action.CREATE;

  const taskConfig = useQuery({
    queryKey: getQueryKey(action, props.config.business?.value),
    queryFn: () =>
      AuthServiceApi.getPermissionsByBusinessRequest({
        action,
        resource: Resource.TASK,
        business: props.config.business?.value,
      }),
    enabled: Boolean(props.config.business?.value),
    select: (data) => getAllocationConfigurations(data.authorizedCombinations),
  });

  const handleFilterInputChange =
    (filterKey: keyof Config) => (event: InputChangeEvent) => {
      props.onConfigChange({
        type: 'UPDATE',
        payload: { [filterKey]: event.detail.value },
      });
    };

  const handleRegionChange = (event: SelectChangeEvent) => {
    props.onConfigChange({
      type: 'UPDATE-REGION',
      payload: {
        region: event.detail.selectedOption,
        country: getCountryOptions(
          props.config.methodology?.value,
          event.detail.selectedOption.value,
          taskConfig.data,
        ),
      },
    });
  };

  const handleFilterSelectChange =
    (filterKey: keyof Config) => (event: SelectChangeEvent) => {
      props.onConfigChange({
        type: `UPDATE-${filterKey.toUpperCase()}`,
        payload: { [filterKey]: event.detail.selectedOption },
      });
    };

  return (
    <Container>
      <SpaceBetween size="m">
        <FormField label={t('business')} stretch>
          <Select
            data-testid="business"
            options={getBusinessOptions()}
            onChange={handleFilterSelectChange('business')}
            selectedOption={props.config.business}
            placeholder={t('business_placeholder')}
          />
        </FormField>
        <FormField label={t('methodology')} stretch>
          <Select
            data-testid="methodology"
            loadingText={taskConfig.isLoading ? t('table_loading') : ''}
            statusType={
              taskConfig.isLoading
                ? DropdownStatus.LOADING
                : DropdownStatus.FINISHED
            }
            options={getMethodologyOptions(taskConfig.data)}
            onChange={handleFilterSelectChange('methodology')}
            selectedOption={props.config.methodology}
            placeholder={t('methodology_placeholder')}
            disabled={action === Action.UPDATE || !props.config.business}
          />
        </FormField>
        <FormField label={t('name')} stretch>
          <Input
            data-testid="name"
            value={getName(props.config)}
            placeholder={t('name_placeholder')}
            disabled
            readOnly
          />
        </FormField>
        <FormField
          label={t('description')}
          constraintText={t('description_constraint')}
          stretch
        >
          <Input
            data-testid="description"
            value={props.config.description}
            onChange={handleFilterInputChange('description')}
            placeholder={t('description_placeholder')}
          />
        </FormField>
        <FormField label={t('region')} stretch>
          <Select
            data-testid="region"
            loadingText={taskConfig.isLoading ? t('table_loading') : ''}
            statusType={
              taskConfig.isLoading
                ? DropdownStatus.LOADING
                : DropdownStatus.FINISHED
            }
            options={getRegionOptions(
              props.config.methodology?.value,
              taskConfig.data,
            )}
            onChange={handleRegionChange}
            selectedOption={props.config.region}
            placeholder={t('region_placeholder')}
            disabled={action === Action.UPDATE || !props.config.methodology}
          />
        </FormField>
        <FormField label={t('country')} stretch>
          <Multiselect
            data-testid="country"
            loadingText={taskConfig.isLoading ? t('table_loading') : ''}
            statusType={
              taskConfig.isLoading
                ? DropdownStatus.LOADING
                : DropdownStatus.FINISHED
            }
            options={getCountryOptions(
              props.config.methodology?.value,
              props.config.region?.value,
              taskConfig.data,
            )}
            selectedOptions={props.config.country}
            placeholder={t('country_placeholder')}
            disabled
          />
        </FormField>
        <FormField label={t('scenario')} stretch>
          <Select
            data-testid="scenario"
            options={getScenario()}
            onChange={handleFilterSelectChange('scenario')}
            selectedOption={props.config.scenario}
            placeholder={t('scenario_placeholder')}
            disabled={action === Action.UPDATE || !props.config.region}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
