import Url from 'src/common/constants/Url';

export interface LandingPageCard {
  name: string;
  href: string;
  description?: string;
  external?: boolean;
}

export interface LandingPageSection {
  title: string;
  cards: LandingPageCard[];
}

export const gettingStartedSection: LandingPageSection = {
  title: 'getting_started',
  cards: [
    {
      name: 'create_driver',
      href: '/driver/create',
      description: 'create_driver_description',
    },
    {
      name: 'manage_driver',
      href: '/driver/manage',
      description: 'manage_driver_description',
    },
    {
      name: 'create_allocation',
      href: '/allocation/create',
      description: 'create_allocation_description',
    },
    {
      name: 'manage_allocation',
      href: '/allocation/manage',
      description: 'manage_allocation_description',
    },
  ],
};

export const resources: LandingPageSection = {
  title: 'resources',
  cards: [
    {
      name: 'mappings',
      href: '/general/mappings',
    },
    {
      name: 'reports',
      href: '/general/reports',
    },
    {
      name: 'wiki_page',
      href: Url.wiki,
      external: true,
    },
    {
      name: 'contact_us',
      href: Url.contactUs,
      external: true,
    },
  ],
};
