import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { TFunc } from 'src/common/types';

export const filteringProperties = (t: TFunc): PropertyFilterProperty[] => [
  {
    propertyLabel: t('name'),
    key: 'mappingName',
    groupValuesLabel: t('group_value_label', { label: t('name') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('methodology'),
    key: 'methodology',
    groupValuesLabel: t('group_value_label', { label: t('methodology') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('last_updated_date'),
    key: 'lastUpdateTimestamp',
    groupValuesLabel: t('group_value_label', { label: t('last_updated_date') }),
    operators: [':', '!:', '=', '!='],
  },
];
