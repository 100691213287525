import TopNavigation from '@cloudscape-design/components/top-navigation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { useNavigate } from 'react-router-dom';
import TicketImage from '../../../public/ticket.png';
import Logo from '../../../public/fae-logo.svg';
import FinergyFloatingMenu from './../../common/components/FinergyFloatingMenu';
import Url from 'src/common/constants/Url';
import { useUserAlias } from 'src/common/hooks/useUserAlias';

/**
 * React component to render common top navigation
 */
export const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { alias } = useUserAlias();

  const handleNavigation = (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
  ) => {
    event.preventDefault();
    navigate(event.detail.href as string);
  };

  return (
    <>
      <FinergyFloatingMenu />
      <TopNavigation
        data-testid="navbar"
        identity={{
          href: '/',
          logo: {
            src: Logo,
            alt: 'title',
          },
        }}
        utilities={[
          {
            type: 'menu-dropdown',
            text: t('drivers'),
            onItemClick: handleNavigation,
            items: [
              {
                id: 'create-driver',
                text: `${t('create_driver')}`,
                href: '/driver/create',
              },
              {
                id: 'manage-driver',
                text: `${t('manage_driver')}`,
                href: '/driver/manage',
              },
            ],
          },
          {
            type: 'menu-dropdown',
            text: t('allocations'),
            onItemClick: handleNavigation,
            items: [
              {
                id: 'create-allocation',
                text: `${t('create_allocation')}`,
                href: '/allocation/create',
              },
              {
                id: 'manage-allocation',
                text: `${t('manage_allocation')}`,
                href: '/allocation/manage',
              },
            ],
          },
          {
            type: 'menu-dropdown',
            text: t('general_information'),
            onItemClick: handleNavigation,
            items: [
              {
                id: 'mappings',
                text: `${t('mappings')}`,
                href: '/general/mappings',
              },
              {
                id: 'reports',
                text: `${t('reports')}`,
                href: '/general/reports',
              },
            ],
          },
          {
            type: 'button',
            iconName: 'folder',
            iconUrl: TicketImage,
            href: Url.Issue,
            target: '_blank',
          },
          {
            type: 'button',
            text: alias,
            iconName: 'user-profile',
            href: `${Url.phoneTool}/${alias}`,
            target: '_blank',
          },
        ]}
        i18nStrings={{
          overflowMenuTriggerText: t('overflow_menu_trigger_text'),
          overflowMenuTitleText: t('overflow_menu_title_text'),
        }}
      />
    </>
  );
};
