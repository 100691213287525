export const ALLOWED_COLUMNS = [
  'account',
  'accountExclusions',
  'costCenter',
  'costCenterExclusions',
  'companyCode',
  'companyCodeExclusions',
  'locationCode',
  'locationCodeExclusions',
  'productCode',
  'productCodeExclusions',
  'projectCode',
  'projectCodeExclusions',
  'channelCode',
  'channelCodeExclusions',
  'allocationFunctions',
  'drivers',
  'taskIdentifier',
];
