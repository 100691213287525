import {
  Icon,
  StatusIndicator,
  TokenGroup,
} from '@cloudscape-design/components';
import React, { FC, useEffect, useState } from 'react';
import {
  Tree,
  TreeCheckboxSelectionKeys,
  TreeEventNodeEvent,
  TreeFilterValueChangeEvent,
  TreeSelectionEvent,
} from 'primereact/tree';
import { useDebounce } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { useTokens } from './useTokens';
import { useOutsideClick } from 'src/common/hooks';
import { TokenGroupsDismissEvent } from 'src/common/types/Events';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import './style.scss';

const DEFAULT_TOKEN_LIMIT = 3;

export interface TreeNode {
  id?: string;
  key?: string | number;
  label?: string;
  data?: any;
  children?: TreeNode[];
  leaf?: boolean;
  expanded?: boolean;
}

interface TreeSelectProps {
  disabled?: boolean;
  initialFilterValue?: string;
  loading?: boolean;
  onChange?: (event: TreeSelectionEvent) => void;
  onDismiss?: (value: string) => void;
  onFilterValueChange?: (event: { value: string }) => void;
  onLoadItems?: (event: TreeNode) => void;
  options?: TreeNode[];
  placeholder?: string;
  selectedKeys?: TreeCheckboxSelectionKeys;
  testId?: string;
  tokenLimit?: number;
  isLoadingInitialFilterValue?: boolean;
}

export const TreeSelect: FC<TreeSelectProps> = (props) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState<string>(
    props.initialFilterValue ?? '',
  );
  const { ref, isExpanded, setIsExpanded } = useOutsideClick();
  const debouncedFilterValue = useDebounce(filterValue, 500);
  const tokens = useTokens(props.selectedKeys ?? {});

  /**
   * Invoke filter when debouncedFilterValue changes
   */
  const { onFilterValueChange } = props;
  useEffect(() => {
    if (!debouncedFilterValue) {
      return;
    }
    onFilterValueChange?.({ value: debouncedFilterValue });
  }, [debouncedFilterValue, onFilterValueChange]);

  /**
   * This function will handle filter changes
   * @param event - TreeFilterValueChangeEvent
   */
  const handleFilterValueChange = (event: TreeFilterValueChangeEvent) => {
    if (!event.value) {
      onFilterValueChange?.({ value: '' });
    }
    setFilterValue(event.value);
  };

  /**
   * This function will remove tokens
   * @param event - TokenGroupsDismissEvent
   */
  const handleDismiss = (event: TokenGroupsDismissEvent) => {
    const tokenIndex = event.detail.itemIndex;
    props.onDismiss?.(tokens[tokenIndex].key);
  };

  /**
   * This function will handle expansion of selected node
   * @param event - TreeEventNodeEvent
   */
  const handleExpand = (event: TreeEventNodeEvent) => {
    props.onLoadItems?.({ ...event.node, expanded: true });
  };

  /**
   * This function will handle collapse of selected node
   * @param event - TreeEventNodeEvent
   */
  const handleCollapse = (event: TreeEventNodeEvent) => {
    props.onLoadItems?.({ ...event.node, expanded: false });
  };

  /**
   * This function will show/hide the dropdown
   */
  const handleDropdownTrigger = () => {
    props.onLoadItems?.({ expanded: !isExpanded, key: '' });
    setIsExpanded(!isExpanded);
  };

  return (
    <div ref={ref} className="tree-select" data-testid={props.testId}>
      <button
        disabled={props.disabled || props.isLoadingInitialFilterValue}
        className="trigger"
        data-testid="trigger"
        onClick={() => handleDropdownTrigger()}
      >
        <span className="placeholder">
          {!props.isLoadingInitialFilterValue && props.placeholder}
          {props.isLoadingInitialFilterValue && (
            <StatusIndicator type="loading">
              {t('table_loading')}
            </StatusIndicator>
          )}
        </span>
        <Icon name={isExpanded ? 'caret-up-filled' : 'caret-down-filled'} />
      </button>
      <Tree
        data-testid="tree"
        className={`dropdown ${isExpanded ? 'active' : ''}`}
        filter={Boolean(props.onFilterValueChange)}
        loading={props.loading}
        onSelectionChange={props.onChange}
        onExpand={handleExpand}
        onCollapse={handleCollapse}
        onFilterValueChange={handleFilterValueChange}
        selectionKeys={props.selectedKeys}
        selectionMode="checkbox"
        value={props.options}
        filterValue={filterValue}
        filterIcon={<Icon name="search" />}
      />
      <TokenGroup
        data-testid={`${props.testId}-token-group`}
        items={tokens}
        limit={props.tokenLimit ?? DEFAULT_TOKEN_LIMIT}
        onDismiss={handleDismiss}
      />
    </div>
  );
};
