import {
  Button,
  ColumnLayout,
  Container,
  ExpandableSection,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Business } from '../config';
import { columnDefinitions } from '../config/driver-mapping-table';
import { filteringProperties } from '../config/driver-mapping-filter-property';
import { ValueWithLabel } from 'src/common/components/ValueWithLabel';
import { Schedule } from 'src/common/types';
import { Config } from 'src/common/types/Config';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { Table } from 'src/common/components/Table';
import { getContentDisplayOptions } from 'src/common/utils/table';

interface ReviewStepInterface {
  config: Config;
  schedule: Schedule;
  onEdit(step: number): void;
}

export const ReviewStep: FC<ReviewStepInterface> = (props) => {
  const { t } = useTranslation();
  const colDefs = useMemo(() => columnDefinitions(t), [t]);

  return (
    <SpaceBetween size="m">
      <Container
        header={
          <Header
            variant="h3"
            actions={
              <Button
                data-testid="allocation-configuration-step-edit"
                onClick={() => props.onEdit(0)}
              >
                {t('edit')}
              </Button>
            }
          >
            {t('allocation')}
          </Header>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <ValueWithLabel label={t('name')}>{props.config.name}</ValueWithLabel>
          <ValueWithLabel label={t('description')}>
            {props.config.description}
          </ValueWithLabel>
          <ValueWithLabel label={t('methodology')}>
            {props.config.methodology?.value}
          </ValueWithLabel>
          <ValueWithLabel label={t('business')}>
            {props.config.business?.value}
          </ValueWithLabel>
          <ValueWithLabel label={t('region')}>
            {props.config.region?.value}
          </ValueWithLabel>
          <ValueWithLabel label={t('country')}>
            {props.config.country.map((option) => option.value).join(', ')}
          </ValueWithLabel>
          <ValueWithLabel label={t('scenario')}>
            {props.config.scenario?.value}
          </ValueWithLabel>
        </ColumnLayout>
      </Container>
      {props.config.business?.value === Business.AB && (
        <Container
          header={
            <Header
              variant="h3"
              actions={
                <Button
                  data-testid="driver-mapping-step-edit"
                  onClick={() => props.onEdit(1)}
                >
                  {t('edit')}
                </Button>
              }
            >
              {t('driver_mappings')}
            </Header>
          }
        >
          <SpaceBetween size="s">
            <ColumnLayout variant="text-grid" columns={3}>
              <ValueWithLabel label={t('driver_mappings')}>
                {props.config.driverMappings?.length
                  ? `${props.config.driverMappings?.length} ${t(
                      'driver_mappings',
                    )}`
                  : t('empty_driver_mappings')}
              </ValueWithLabel>
              <ValueWithLabel label={t('allocation_function')}>
                {props.config.driverMappings?.[0]?.allocationFunction}
              </ValueWithLabel>
            </ColumnLayout>
            <ExpandableSection headerText={t('additional_driver_mappings')}>
              <Table<DriverMapping>
                pagination
                preferences
                variant="borderless"
                testid="review-driver-mapping-table"
                columnDefinitions={colDefs}
                data={props.config.driverMappings ?? []}
                contentDisplayOptions={getContentDisplayOptions(colDefs)}
                filteringProperties={filteringProperties(t)}
              />
            </ExpandableSection>
          </SpaceBetween>
        </Container>
      )}
      {props.config.business?.value !== Business.AB && (
        <Container
          header={
            <Header
              variant="h3"
              actions={
                <Button
                  data-testid="schedule-step-edit"
                  onClick={() => props.onEdit(1)}
                >
                  {t('edit')}
                </Button>
              }
            >
              {t('schedule')}
            </Header>
          }
        >
          <ColumnLayout variant="text-grid" columns={3}>
            <ValueWithLabel label={t('enabled')}>
              {props.schedule.enable ? t('true') : t('false')}
            </ValueWithLabel>
            <ValueWithLabel label={t('schedule')}>
              {props.schedule.enable
                ? t('schedule_value', {
                    workdayStart: props.schedule.workdayStart?.value,
                    workdayEnd: props.schedule.workdayEnd?.value,
                  })
                : '-'}
            </ValueWithLabel>
            <ValueWithLabel label={t('time', { timezone: 'PT' })}>
              {props.schedule.enable ? props.schedule.time?.value : '-'}
            </ValueWithLabel>
          </ColumnLayout>
        </Container>
      )}
    </SpaceBetween>
  );
};
