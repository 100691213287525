import { TreeCheckboxSelectionKeys } from 'primereact/tree';
import { useMemo } from 'react';

export const getSelectionKeys = (selectedKeys: TreeCheckboxSelectionKeys) => {
  const selectedValuesWithCheckedAttribute = new Set(
    Object.keys(selectedKeys).filter((key) => selectedKeys?.[key].checked),
  );

  const selectedValuesAfterRemovingChildKeys = new Set<string>();

  for (const key of selectedValuesWithCheckedAttribute) {
    let isChild = false;
    for (const parentKey of selectedValuesWithCheckedAttribute) {
      if (key !== parentKey && key.includes(parentKey)) {
        isChild = true;
        break;
      }
    }
    if (!isChild) {
      selectedValuesAfterRemovingChildKeys.add(key);
    }
  }
  return Array.from(selectedValuesAfterRemovingChildKeys);
};

/**
 * This hook will return the selected tokens for tree
 * @param selectedKeys TreeCheckboxSelectionKeys
 * @returns tokens
 */
export const useTokens = (selectedKeys: TreeCheckboxSelectionKeys) =>
  useMemo(() => {
    const selectedValuesAfterRemovingChildKeys = getSelectionKeys(selectedKeys);
    return selectedValuesAfterRemovingChildKeys.map((key) => ({
      key,
      label: key.split('|').pop(),
    }));
  }, [selectedKeys]);
