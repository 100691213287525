import { CollectionPreferencesProps } from '@cloudscape-design/components/collection-preferences';
import { TableProps } from '@cloudscape-design/components/table';
import { StatusIndicatorProps } from '@cloudscape-design/components/status-indicator';
import { TFunc } from '../types';
import { EventStatus } from '../types/EventStatus';

export const eventStatusVsStatusIndicatorTypeMap: Record<
  string,
  StatusIndicatorProps.Type
> = {
  [EventStatus.APPROVED]: 'success',
  [EventStatus.FAILURE]: 'error',
  [EventStatus.PENDING_REVIEW]: 'pending',
  [EventStatus.REJECTED]: 'error',
  [EventStatus.RUNNING]: 'in-progress',
  [EventStatus.STARTED]: 'in-progress',
  [EventStatus.SUCCESS]: 'success',
  [EventStatus.NOT_STARTED]: 'stopped',
};

export const defaultPreference: CollectionPreferencesProps.Preferences = {
  pageSize: 30,
  contentDensity: 'comfortable',
  wrapLines: true,
  stripedRows: false,
  contentDisplay: [],
};

export const getTextFilterCounterText = (count: number | undefined, t: TFunc) =>
  t('filter_counter', { count });

export const getContentDisplayOptions = <T>(
  columnDefinitions: TableProps.ColumnDefinition<T>[],
) =>
  columnDefinitions.map((def) => ({
    id: def.id ?? '',
    label: typeof def.header === 'string' ? def.header : '',
  }));
