import { Flashbar, FlashbarProps } from '@cloudscape-design/components';
import React from 'react';
import { useNotificationContext } from '../provider/NotificationProvider';

export const Notification = () => {
  const { notification, clearNotification } = useNotificationContext();

  const items: FlashbarProps.MessageDefinition[] = [];

  if (!Object.keys(notification).length) {
    return <></>;
  }

  items.push({
    ...notification,
    dismissible: true,
    onDismiss: clearNotification,
  });

  return <Flashbar data-testid="" items={items} />;
};
