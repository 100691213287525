import { Config } from 'src/common/types/Config';

export const methodologies = ['FAM', 'GAM', 'AB'];
export const scenarios = ['Actuals'];

const createSelectOptions = (options: string[]) =>
  options.map((option) => ({
    label: option,
    value: option,
  }));

export const getName = (config: Config): string =>
  [
    config.methodology?.value ?? '',
    config.business?.value ?? '',
    config.region?.value ?? '',
    config.scenario?.value ?? '',
  ]
    .filter((value) => value)
    .join('-');

export const getScenario = () => createSelectOptions(scenarios);
