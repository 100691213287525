import { Reducer } from 'react';
import { Config, defaultConfig } from 'src/common/types/Config';

export const reducer: Reducer<
  Config,
  { type: string; payload?: Partial<Config> }
> = (state, action) => {
  switch (action.type) {
    case 'UPDATE-COUNTRY':
    case 'UPDATE-SCENARIO':
    case 'UPDATE': {
      return { ...state, ...action.payload };
    }
    case 'UPDATE-METHODOLOGY': {
      return {
        ...state,
        ...action.payload,
        region: null,
        country: [],
        scenario: null,
      };
    }
    case 'UPDATE-BUSINESS': {
      return {
        ...state,
        ...action.payload,
        region: null,
        country: [],
        scenario: null,
      };
    }
    case 'UPDATE-REGION': {
      return {
        ...state,
        ...action.payload,
        scenario: null,
      };
    }
    case 'RESET': {
      return defaultConfig;
    }
    default:
      return state;
  }
};
