import Box from '@cloudscape-design/components/box';
import React, { FC, ReactNode } from 'react';

interface ValueWithLabelProps {
  children: ReactNode;
  label: string;
}

export const ValueWithLabel: FC<ValueWithLabelProps> = (props) => (
  <div>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);
