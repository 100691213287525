import {
  GetDefaultValuesResponse,
  GetDimensionsRequest,
  GetDimensionsResponse,
  GetDriverMappingsRequest,
  GetDriverMappingsResponse,
  GetExecutionEventsRequest,
  GetExecutionEventsResponse,
  GetHierarchyNamesForDimensionRequest,
  GetHierarchyNamesForDimensionResponse,
  GetReportMetadataRequest,
  GetReportMetadataResponse,
  GetStaticDriverMetadataRequest,
  GetStaticDriverMetadataResponse,
  GetStaticDriversResponse,
  PutApprovalStatusRequest,
  PutApprovalStatusResponse,
} from '@amzn/allocations-service';
import { Api } from './Api';

const API = new Api('FinanceAllocationsService');

export const AllocationServiceApi = {
  getReportMetadata(
    request: GetReportMetadataRequest,
  ): Promise<GetReportMetadataResponse> {
    return API.post('/getReportMetadata', request);
  },
  getStaticDriverMetadata(
    request: GetStaticDriverMetadataRequest,
  ): Promise<GetStaticDriverMetadataResponse> {
    return API.post('/getStaticDriverMetadata', request);
  },
  putApprovalStatus(
    request: PutApprovalStatusRequest,
  ): Promise<PutApprovalStatusResponse> {
    return API.post('/putApprovalStatus', request);
  },
  getExecutionEvents(
    request: GetExecutionEventsRequest,
  ): Promise<GetExecutionEventsResponse> {
    return API.post('/getExecutionEvents', request);
  },
  getDriverMappings(
    request: GetDriverMappingsRequest,
  ): Promise<GetDriverMappingsResponse> {
    return API.post('/driverMappings', request);
  },
  getDimensions(request: GetDimensionsRequest): Promise<GetDimensionsResponse> {
    return API.post('/getDimensions', request);
  },
  getStaticDrivers: (): Promise<GetStaticDriversResponse> =>
    API.get('/staticDrivers'),
  getDefaultValuesByCOASegment: (): Promise<GetDefaultValuesResponse> =>
    API.get('/defaultValues'),
  getHierarchyNamesForDimension: (
    request: GetHierarchyNamesForDimensionRequest,
  ): Promise<GetHierarchyNamesForDimensionResponse> =>
    API.post('/getHierarchyNamesForDimension', request),
};
