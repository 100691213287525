import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { StaticDriverMappingMetadata } from '@amzn/allocations-service';
import { columnDefinitions } from './config/table-config';
import { filteringProperties } from './config/filter-property';
import { Table } from 'src/common/components/Table';
import { getContentDisplayOptions } from 'src/common/utils/table';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';

const MappingsPage = () => {
  const { t } = useTranslation();
  const columnDef = useMemo(() => columnDefinitions(t), [t]);
  const mappings = useQuery({
    queryKey: [QueryKey.GetStaticDriverMetadata],
    queryFn: () => AllocationServiceApi.getStaticDriverMetadata({}),
  });

  return (
    <div data-testid="mappings-page">
      <Table<StaticDriverMappingMetadata>
        variant="full-page"
        columnDefinitions={columnDef}
        contentDisplayOptions={getContentDisplayOptions(columnDef)}
        data={mappings.data?.mappings ?? []}
        testid="mappings-table"
        header={t('mappings')}
        pagination={true}
        preferences={true}
        empty={{
          title: t('empty_mappings'),
          description: t('empty_mappings_description'),
        }}
        filteringProperties={filteringProperties(t)}
        loading={mappings.isFetching}
      />
    </div>
  );
};

export default MappingsPage;
