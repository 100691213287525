import {
  GetPermissionsByBusinessRequest,
  GetPermissionsByBusinessResponse,
  HasPermissionRequest,
  HasPermissionResponse,
} from '@amzn/fae-auth-service';
import { Api } from './Api';

const API = new Api('FAEAuthService');

export const AuthServiceApi = {
  getPermissionsByBusinessRequest(
    request: GetPermissionsByBusinessRequest,
  ): Promise<GetPermissionsByBusinessResponse> {
    return API.post('/businessPermissions/get', request);
  },
  hasPermissions(
    request: HasPermissionRequest,
  ): Promise<HasPermissionResponse> {
    return API.post('/permission/check', request);
  },
};
