import Box from '@cloudscape-design/components/box';
import SpaceBetween from '@cloudscape-design/components/space-between';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const TableNoMatchState: FC = () => {
  const { t } = useTranslation();

  return (
    <Box textAlign="center" color="inherit" data-testid="no-match-table">
      <SpaceBetween size="xxs">
        <div>
          <b>{t('no_matches')}</b>
          <Box variant="p" color="inherit">
            {t('no_matches_description')}
          </Box>
        </div>
      </SpaceBetween>
    </Box>
  );
};
