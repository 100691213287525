import React from 'react';
import { RouteObject } from 'react-router-dom';
import { PrivateRoute } from './pages/layout/PrivateRoute';
import LandingPage from './pages/landing-page/LandingPage';
import AllocationConfigurationPage from './pages/create-allocation-page';
import CreateDriverPage from './pages/create-driver-page';
import ManageAllocationPage from './pages/manage-allocation-page';
import ManageDriverPage from './pages/manage-driver-page';
import MappingsPage from './pages/mappings-page';
import ReportsPage from './pages/reports-page';
import DriverDetailsPage from './pages/driver-details-page';
import DriverExecutionDetailsTab from './pages/driver-details-page/ExecutionDetailsTab';
import AllocationExecutionDetailsTab from './pages/allocation-details-page/ExecutionDetailsTab';
import AllocationDetailsPage from './pages/allocation-details-page';

// App routes
export const routes: RouteObject[] = [
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'driver',
        children: [
          {
            path: 'create',
            element: <CreateDriverPage />,
          },
          {
            path: 'manage',
            element: <ManageDriverPage />,
          },
          {
            path: 'update/:taskId',
            element: <CreateDriverPage />,
          },
          {
            path: 'details',
            element: <DriverDetailsPage />,
            children: [
              {
                path: ':taskId',
                element: <DriverExecutionDetailsTab />,
              },
            ],
          },
        ],
      },
      {
        path: 'allocation',
        children: [
          {
            path: 'create',
            element: <AllocationConfigurationPage />,
          },
          {
            path: 'manage',
            element: <ManageAllocationPage />,
          },
          {
            path: 'update/:taskId',
            element: <AllocationConfigurationPage />,
          },
          {
            path: 'details',
            element: <AllocationDetailsPage />,
            children: [
              {
                path: ':taskId',
                element: <AllocationExecutionDetailsTab />,
              },
            ],
          },
        ],
      },
      {
        path: 'general',
        children: [
          {
            path: 'mappings',
            element: <MappingsPage />,
          },
          {
            path: 'reports',
            element: <ReportsPage />,
          },
        ],
      },
    ],
  },
];
