import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks';
import { TaskExecution } from '@amzn/taskscheduling-service';
import { TFunc } from 'src/common/types';

export const filteringProperties = (
  t: TFunc,
): PropertyFilterProperty<TaskExecution>[] => [
  {
    propertyLabel: t('execution_id'),
    key: 'executionId',
    groupValuesLabel: t('group_value_label', { label: t('execution_id') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('version_number'),
    key: 'taskVersion',
    groupValuesLabel: t('group_value_label', { label: t('version_number') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('status'),
    key: 'status',
    groupValuesLabel: t('group_value_label', { label: t('status') }),
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: t('start_time'),
    key: 'time',
    groupValuesLabel: t('group_value_label', { label: t('start_time') }),
    operators: [':', '!:', '=', '!='],
  },
];
