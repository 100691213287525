import { useCallback, useEffect, useRef, useState } from 'react';

const useOutsideClick = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        isExpanded
      ) {
        setIsExpanded(false);
      }
    },
    [ref, isExpanded],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, !isExpanded);

    return () => {
      document.removeEventListener('click', handleClickOutside, !isExpanded);
    };
  }, [ref, handleClickOutside, isExpanded]);

  return { ref, isExpanded, setIsExpanded };
};

export default useOutsideClick;
